import { translate } from "utils/language";
import bootstrap, { BootstrapCatalogue } from "bootstrap";
import { emptyArray, emptyObject, tuple } from "./constants";
import roles, { getRoles } from "./roles";
import { Dictionary, type DictionaryRO } from "./types";
import type { IAppBar } from "components/shell/appbar/AppBar";

interface RawPage {
  path?: string
  fullPath?: string
  icon?: string
  displayName?: string
  searchName?: string
  sortKey?: string
  description?: string
  keywords?: readonly string[]
  
  displayInBreadcrumb?: boolean
  nextStepRequired?: boolean
  terminateBreadcrumb?: boolean
  
  /** Czy wyświetlać tego potomka w sidebarze? */
  sb?: boolean
  
  /** Czy to strona pełnoekranowa? */
  fs?: boolean
  
  /** Wymagania by dany link i jego potomkowie był widoczny */
  rq?: string | readonly string[]
  
  /** Czy sortować potomków alfabetycznie? (w sidebarze) */
  sort?: boolean

  appBar?: IAppBar

  children?: Dictionary<RawPage>
}

export type CookedPage<T extends RawPage = {}> = {
  id: string
  path: string
  fullPath: string
  icon: string
  displayName: string
  searchName: string
  sortKey: string
  description: string
  keywords: readonly string[]
  
  /** Przestarzałe, lista ról które mają dostęp */
  access: readonly string[]
  
  disableForSearching: boolean
  displayInBreadcrumb: boolean
  nextStepRequired: boolean
  terminateBreadcrumb: boolean
  
  /** Czy wyświetlać tego potomka w sidebarze? */
  sidebar: boolean

  appBar?: IAppBar
  
  /** Czy to strona pełnoekranowa? */
  fullscreen?: boolean
  
  /** Wymagania by dany link i jego potomkowie był widoczny */
  requirements: readonly string[]
  
  /** Czy sortować potomków alfabetycznie? (w sidebarze) */
  sort: boolean
  
  children: Readonly<CookedPageChildren<T["children"]>>
  childrenByPath: DictionaryRO<CookedPage>
  
  checkRequirements(met: Set<string>): boolean
}

export type PageRoot<T extends Dictionary<RawPage>> = { [K in keyof T]: CookedPage<T[K]> };

// TODO: leniwe tłumaczenie nazw stron

export type CookedPageChildren<T> = T extends Dictionary<RawPage>
  ? { [K in keyof T]: Readonly<CookedPage<T[K]>> }
  : {};

const _pages = {
  home: {
    path: "/",
    fullPath: "/",
    icon: "home",
    displayName: translate("app.home"),
    displayInBreadcrumb: false,
  },
  admin: {
    icon: "id badge",
    displayName: translate("app.admin"),
    sb: true,
    rq: "staff",
    sort: true,
    children: {
      users: {
        displayName: translate("pages.list.users.title"),
        description: translate("pages.list.users.description"), // wykorzystywane w wyświetlaniu wyników wyszukiwania i w headerze
        icon: "users", // wykorzystywane w wyświetlaniu wyników wyszukiwania
        displayInBreadcrumb: true,
        rq: "staff",
        sb: true,
        keywords: [
          "użytkownik",
          "lista",
          "edycja",
          "pracownicy",
          "administratorzy",
          "users",
          "list",
          "workers",
          "admin"
        ],
        children: {
          v: {
            displayName: translate("pages.list.users.single.title"),
            fullPath: "/admin/users/v/:id",
            nextStepRequired: true, // link w breadcrumbach będzie generowany na podstawie atrybutu 'path',
            // w przypadku stronu użytkownika link wygląda /users/view/login
            // nextStepRequired zaznacza, że w trakcie generowania tego linku potrzebujemy ten 'krok' dalej,
            // tak, żeby nie wygenerować tylko /users/view/
            children: {
              edit: {
                displayName: translate("pages.list.users.single.edit.title"),
              },
            }
          }
        }
      }, // users
      groups: {
        displayName: translate("pages.list.groups.title"),
        description: translate("pages.list.groups.description"), // wykorzystywane w wyświetlaniu wyników wyszukiwania i w headerze
        icon: "users", // wykorzystywane w wyświetlaniu wyników wyszukiwania
        displayInBreadcrumb: true,
        nextStepRequired: true,
        keywords: ["grupy"],
        children: {
          v: {
            displayName: translate("pages.list.users.single.title"),
            fullPath: "/admin/groups/v/:id",
            nextStepRequired: true // link w breadcrumbach będzie generowany na podstawie atrybutu 'path',
            // w przypadku stronu użytkownika link wygląda /users/view/login
            // nextStepRequired zaznacza, że w trakcie generowania tego linku potrzebujemy ten 'krok' dalej,
            // tak, żeby nie wygenerować tylko /users/view/
          }
        }
      }, // groups
      sessions: {
        keywords: [
          "zalogowani",
          "aktywni",
          "wyloguj",
          "logged",
          "active",
          "online"
        ],
        rq: "gdpr",
        sb: true,
        displayName: translate("pages.list.sessions.title"),
        description: translate("pages.list.sessions.description"),
        icon: "user-clock"
      }, // sessions
      licenses: {
        displayName: translate("pages.list.licenses.title"),
        description: translate("pages.list.licenses.description"),
        icon: "copyright",
        sb: true,
        rq: "teka",
        children: {
          v: {
            fullPath: "/admin/licenses/v/:license_id",
            nextStepRequired: true,
            displayName: translate("pages.list.licenses.single.title"),
            description: translate("pages.list.licenses.single.description"),
          }
        }
      }, // licenses
      serials: {
        displayName: "E-Prasa",
        searchName: "E-Prasa (administracja)",
        description: "Zarządzaj czasopismami udostępnianymi cyfrowo",
        keywords: ["czasopisma"],
        icon: "newspaper-outline",
        rq: "tekaSerials",
        sb: true,
      },
      applications: {
        keywords: ["wniosek"],
        icon: "handshake-outline",
        displayName: translate("pages.list.applications.title"),
        description: translate("pages.list.applications.description"),
        sb: true,
        children: {
          editor: {
            displayInBreadcrumb: false,
            keywords: ["wniosek"],
            icon: "handshake-outline",
            rq: "tekaEditors",
            displayName: translate("pages.list.applications.title"),
            description: translate("pages.list.applications.description")
          },
          catalogue: {
            displayInBreadcrumb: false,
            keywords: ["wniosek"],
            icon: "handshake-outline",
            displayName: translate("pages.list.applications.title"),
            description: translate("pages.list.applications.description")
          },
          access: {
            displayInBreadcrumb: false,
            keywords: ["wniosek"],
            icon: "handshake-outline",
            displayName: translate("pages.list.applications.title"),
            description: translate("pages.list.applications.description")
          },
          edit: {
            fullPath: "/admin/applications/edit/:id",
            displayInBreadcrumb: false,
            nextStepRequired: true,
            icon: "handshake-outline",
            displayName: translate("pages.list.applications.edit.title"),
            description: translate("pages.list.applications.edit.description")
          }
        }
      }, // applications
      issues: {
        keywords: [
          "issues",
          "reports",
          "teka",
          "documents",
          "dokumenty",
          "zgłoszenia",
          "problemy"
        ],
        icon: "exclamation-triangle",
        displayName: translate("pages.list.issues.title"),
        description: translate("pages.list.issues.description"),
        sb: true,
      },
      reports: {
        keywords: ["reports", "raporty"],
        icon: "chart-mixed",
        displayName: translate("pages.list.reports.title"),
        description: translate("pages.list.reports.description"),
        sb: true,
        children: {
          report: {
            displayName: translate("pages.list.reports.report.title"),
            fullPath: "/admin/reports/report/:id",
            nextStepRequired: true // link w breadcrumbach będzie generowany na podstawie atrybutu 'path',
            // w przypadku stronu użytkownika link wygląda /users/view/login
            // nextStepRequired zaznacza, że w trakcie generowania tego linku potrzebujemy ten 'krok' dalej,
            // tak, żeby nie wygenerować tylko /users/view/
          }
        }
      },
      tools: {
        rq: "admin",
        keywords: [
          "konfiguracja",
          "parametry",
          "ustawienia",
          "maile",
          "agendy",
          "tłumaczenia",
          "wyszukiwanie",
          "configuration",
          "parameters",
          "settings",
          "options",
          "search"
        ],
        displayName: translate("app.admin.tools"),
        icon: "wrench",
        sort: true,
        sb: true,
        sortKey: "ZZZZ",
        children: {
          agenda: {
            displayName: translate("pages.list.tools.agenda.title"),
            description: translate("pages.list.tools.agenda.description"),
            icon: "building",
            keywords: ["filia", "agenda", "oddział"],
            rq: "admin",
            sb: true,
          },
          translations: {
            path: "/translations",
            fullPath: "/admin/tools/translations",
            displayName: translate("pages.list.tools.translations.title"),
            description: translate("pages.list.tools.translations.description"),
            icon: "building",
            keywords: ["tłumaczenia", "translate", "enum"],
            disableForSearching: true
          },
          buyproposal: {
            displayName: translate("pages.list.tools.buyproposal.title"),
            description: translate("pages.list.tools.buyproposal.description"),
            icon: "shopping-basket",
            keywords: ["kupno", "propozycja"],
            rq: "admin",
            sb: true,
          },
          passwords: {
            displayName: translate("pages.list.tools.passwords.title"),
            description: translate("pages.list.tools.passwords.description"),
            icon: "key",
            keywords: [
              "użytkownicy",
              "hasło",
              "długość hasła",
              "passwords",
              "security",
              "users"
            ],
            rq: "admin",
            sb: true,
          },
          parameters: {
            displayName: translate("pages.list.tools.params.title"),
            description: translate("pages.list.tools.params.description"),
            keywords: ["wszystko"],
            icon: "hashtag",
            children: {
              editor: {
                displayName: translate("pages.list.tools.params.editor"),
              }
            },
            rq: "admin",
            sb: true,
          },
          reports: {
            displayName: translate("pages.list.tools.reports.title"),
            description: translate("pages.list.tools.reports.description"),
            keywords: ["wszystko"],
            icon: "flag",
            rq: "admin",
            sb: true,
          },
          eprasaVendors: {
            displayName: "Dostawcy e-prasy",
            description: "Skonfiguruj sposób importu e-prasy od dostawców",
            keywords: ["wszystko"],
            icon: "newspaper-outline",
            rq: ["tekaSerials", "admin"],
            sb: true,
          },
          schedule: {
            displayName: translate("pages.list.tools.schedule.title"),
            description: translate("pages.list.tools.schedule.description"),
            keywords: ["wszystko"],
            icon: "calendar alt outline",
            rq: "admin",
            sb: true,
          },
          sharingrules: {
            displayName: translate("pages.list.tools.sharingrules.title"),
            description: translate("pages.list.tools.sharingrules.description"),
            keywords: [
              "reguły udostępniania",
              "reguły",
              "rules",
              "sharing rules"
            ],
            icon: "sitemap",
            rq: "admin",
            sb: true,
            children: {
              editor: {
                displayName: translate("pages.list.tools.sharingrules.editor.title"),
              }
            }
          },
          discardrules: {
            displayName: translate("pages.list.tools.discardrules.title"),
            description: translate("pages.list.tools.discardrules.description"),
            keywords: [
              "reguły ubytkowania",
              "reguły",
              "rules",
              "lossing rules"
            ],
            icon: "sitemap",
            rq: "admin",
            sb: true,
          },
          servers: {
            keywords: [
              "servers",
              "file",
            ],
            icon: "server",
            rq: "admin",
            sb: true,
            displayName: translate("pages.list.tools.docFileServers.title"),
            description: translate("pages.list.tools.docFileServers.description"),
          },
          payments: {
            displayName: translate("pages.list.tools.payments.title"),
            description: translate("pages.list.tools.payments.description"),
            keywords: ["payments", "payu"],
            icon: "credit card",
            rq: ["kasa", "senior"],
            sb: true,
          },
          leasing: {
            displayName: translate("pages.list.tools.leasing.title"),
            description: translate("pages.list.tools.leasing.description"),
            keywords: ["leasing"],
            icon: "book-reader",
            rq: ["teka", "admin"],
            sb: true,
            displayInBreadcrumb: true
          },
          sms: {
            displayName: translate("pages.list.tools.sms.title"),
            description: translate("pages.list.tools.sms.description"),
            keywords: ["sms"],
            icon: "sms",
            displayInBreadcrumb: true,
            rq: "senior",
            sb: true,
          },
          mailboxes: {
            displayName: translate("pages.list.tools.mailboxes.title"),
            description: translate("pages.list.tools.mailboxes.description"),
            keywords: ["mail"],
            icon: "envelope",
            displayInBreadcrumb: true,
            rq: "senior",
            sb: true,
          },
          usersForms: {
            displayName: translate("pages.list.tools.usersForms.title"),
            description: translate("pages.list.tools.usersForms.description"),
            icon: "users",
            rq: "admin",
            sb: true,
            displayInBreadcrumb: true,
            children: {
              patron: {
                displayInBreadcrumb: false,
              },
              aspirant: {
                displayInBreadcrumb: false,
              },
              // FIXME: a senior i gdpr?
              staff: {
                displayInBreadcrumb: false,
              },
              spectator: {
                displayInBreadcrumb: false,
              },
              admin: {
                displayInBreadcrumb: false,
              }
            }
          },
          notificationsOld: {
            displayName: translate("pages.list.tools.notificationsOld.title"),
            description: translate(
              "pages.list.tools.notificationsOld.description"
            ),
            icon: "envelope",
            displayInBreadcrumb: false,
            rq: "admin",
            children: {
              main: {
                displayName: "Główne ustawienia",
              },
              footer: {
                displayName: "Treść stopki",
              },
              content: {
                displayName: "Treść wiadomości",
              },
            }
          },
          search: {
            keywords: ["search", "reindeks"],
            displayName: translate("pages.list.search.title"),
            description: translate("pages.list.search.description"),
            icon: "search",
            rq: "admin",
            sb: true,
          },
          singleSignOn: {
            keywords: ["logowanie", "authentication"],
            displayName: translate('pages.list.tools.singleSignOn.title'),
            description: translate("pages.list.tools.singleSignOn.description"),
            icon: "key",
            rq: "admin",
            sb: true,
          }
        }
      }
    }
  }, // admin
  teka: {
    icon: "book",
    displayName: translate("app.teka"),
    description: "Teka", // wykorzystywane w wyświetlaniu wyników wyszukiwania
    sb: true,
    rq: "teka",
    children: {
      doc: {
        fullPath: "/teka/doc/:id",
        displayName: translate("pages.list.teka.document.title"),
        terminateBreadcrumb: true, // hak? kończy przetwarzanie statycznych okruszków, nie dopasowuje tej i nast. ścieżek
        nextStepRequired: true, // link w breadcrumbach będzie generowany na podstawie atrybutu 'path',
        // w przypadku stronu użytkownika link wygląda /users/view/login
        // nextStepRequired zaznacza, że w trakcie generowania tego linku potrzebujemy ten 'krok' dalej,
        // tak, żeby nie wygenerować tylko /users/view/
        children: {
          application: {
            fullPath: "/teka/doc/:id/application/:app_id",
            displayName: translate("pages.list.applications.edit.title"),
            nextStepRequired: true
          },
          online: {
            fullPath: "/teka/doc/:id/online",
            displayName: translate("pages.list.teka.documentPreview.title"),
          }
        }
      },
      index: {
        displayName: translate("teka.index"),
        icon: "folder",
        displayInBreadcrumb: false,
        sb: true,
      },
      eprasa: {
        path: "/prasa",
        displayName: "E-Prasa",
        icon: "newspaper",
        displayInBreadcrumb: false,
        terminateBreadcrumb: true,
        sb: true,
      },
      search: {
        displayName: translate("pages.list.teka.search.title"),
        icon: "search",
        displayInBreadcrumb: false,
        terminateBreadcrumb: true,
      },
      labels: {
        displayName: translate("pages.list.teka.labels.title"),
        icon: "tag",
        nextStepRequired: false,
        sb: true,
        rq: "login",
      },
      application: {
        path: "/application",
        fullPath: "/teka/application/:id",
        displayName: translate("pages.list.applications.edit.title"),
        nextStepRequired: true,
      },
    }
  },
  cataloguing: {
    path: "/kat",
    displayName: translate("app.cataloguing"),
    description: translate("app.cataloguingDescription"),
    disableForSearching: true,
    icon: "archive",
    sb: true,
    fs: true,
    rq: ["staff", "beta"],
    appBar: {
      leftActions: [{ name: "appbar.catalogues" }],
      rightActions: [{ name: "appbar.collections" }, { name: "appbar.reports" }, { name: "appbar.programs" }]
    },
    children: perCatalogue((cat, pathId) => ({
      sb: true,
    })),
  },
  locker: {
    path: "/maty",
    displayName: translate("app.locker"),
    description: translate("app.lockerDescription"),
    disableForSearching: true,
    icon: "line-columns",
    sb: true,
    fs: true,
    appBar: {
      leftActions: [{ name: "appbar.lockers" }],
      rightActions: [{ name: "appbar.reports" }]
    },
    rq: ["książkomatyRights", "beta"],
  },
  czyt: {
    displayName: translate("app.journal"),
    description: translate("app.journalDescription"),
    displayInBreadcrumb: false,
    icon: "clipboard",
    appBar: {
      leftActions: [{ name: "appbar.agendas" }],
      rightActions: [{ name: "appbar.preferences" }, { name: "appbar.edit" }, { name: "appbar.settings" }, { name: "appbar.visibility" }],
    },
    rq: ["czytelniaRights", "beta"],
    sb: true,
    fs: true
  },
  stan: {
    displayName: translate("app.workstations"),
    description: translate("app.workstationsDescription"),
    displayInBreadcrumb: false,
    icon: "desktop outline",
    fs: true,
    sb: true,
    appBar: {
      leftActions: [{ name: "appbar.workstations" }],
      rightActions: [{ name: "appbar.reports" }, { name: "appbar.programs" }],
      barcode: true,
    },
    rq: ["stanowiskaRights", "beta"],
  },
  wyp: {
    displayName: translate("app.lending"),
    description: translate("app.lending.desc"),
    displayInBreadcrumb: false,
    icon: "inbox",
    sb: true,
    fs: true,
    rq: ["wypożyczalniaRights", "beta"],
    appBar: {
      leftActions: [{ name: "appbar.agendas" }],
      rightActions: [{ name: "appbar.selected.reset" }, { name: "appbar.reports" }, { name: "appbar.programs" }],
      barcode: true,
    },
    children: {
      dashboard: {
        displayName: translate("pages.list.lending.dashboard.title"),
        description: translate("pages.list.lending.dashboard.description"),
        icon: "table-columns",
        sb: true,
      },
      reservations: {
        displayName: translate("pages.list.lending.reservations.title"),
        description: translate("pages.list.lending.reservations.description"),
        icon: "calendar",
        sb: true,
      },
      monits: {
        displayName: translate("pages.list.lending.monits.title"),
        description: translate("pages.list.lending.monits.description"),
        icon: "bell",
        sb: true,
      },
      orders: {
        displayName: translate("pages.list.lending.orders.title"),
        description: translate("pages.list.lending.orders.description"),
        icon: "box-archive",
        sb: true,
      },
    }
  },
  ubyt: {
    displayName: translate("app.weeding"),
    description: translate("app.weeding.desc"),
    icon: "trash",
    sb: true,
    fs: true,
    rq: ["ubytkowanieRights", "beta"],
    appBar: {
      leftActions: [{ name: "appbar.catalogues" }, { name: "appbar.inventories" }],
      rightActions: [{ name: "appbar.reports" }, { name: "appbar.programs" }],
      barcode: true,
    },
    children: perCatalogue((cat, pathId) => ({
      sb: true,
    }), "ubytkowanieRights:#"),
  },
  myProfile: {
    displayName: translate("pages.list.myProfile.title"),
    description: translate("pages.list.myProfile.description"),
    keywords: ["konto", "account"],
    icon: "user",
    children: {
      edit: {
        displayName: translate("pages.list.users.single.edit.title"),
        description: translate("pages.list.myProfile.edit.description"),
        icon: "edit"
      },
      "edit-address": {
        displayName: translate("pages.list.users.single.address.title"),
        description: translate("pages.list.myProfile.address.description"),
        icon: "phone"
      },
      groups: {
        children: {
          v: {
            fullPath: "/myProfile/groups/v/:id",
          }
        }
      }
    }
  },
  confirm: {
    displayName: translate("pages.list.confirm.title")
  },
  forgot: {
    displayName: translate("pages.list.forgot.title")
  },
  recovery: {
    displayName: translate("pages.list.recovery.title")
  },
  privacypolicy: {
    path: "/privacy-policy",
    displayName: translate("pages.list.privacy-policy.title")
  },
  terms: {
    displayName: translate("pages.list.regulations.title")
  }
};

const fz = Object.freeze;

function checkRequirements(this: CookedPage, met: Set<string>) {
  for (const req of this.requirements) {
    if (!met.has(req)) {
      return false;
    }
  }
  return true;
}

function cookPage<T extends RawPage>(raw: T, parent: CookedPage | null, key: string): CookedPage<T> | null {
  if (!raw.path && !key) {
    console.error("Invalid page: missing path or key", raw);
    return null;
  }
  
  const path = raw.path || `/${key}`;
  const fullPath = raw.fullPath || (parent ? `${parent.fullPath}${path}` : path);
  const rqSet = new Set([...parent?.requirements || emptyArray, ...strArray(raw.rq)]);
  
  const requirements = fz([...rqSet]);
  
  let maxRole = -1;
  for (const req of requirements) {
    const role = roles[req as keyof typeof roles];
    if (role && maxRole < role.rate)
      maxRole = role.rate;
  }
  
  let access: readonly string[] = emptyArray;
  if (maxRole >= 0) {
    // TODO: opt kiedyś
    access = Object.values(roles).filter(r => r.rate >= maxRole).map(r => r.id);
  }
  
  const cooked: CookedPage = {
    id: parent ? `${parent}.${key}` : key,
    path,
    fullPath,
    icon: raw.icon || "square",
    displayName: raw.displayName || "",
    searchName: raw.searchName || raw.displayName || "",
    sortKey: raw.sortKey || raw.displayName || "",
    description: raw.description || "",
    keywords: strArray(raw.keywords),
    access: access,
    requirements,
    disableForSearching: false,
    displayInBreadcrumb: raw.displayInBreadcrumb !== false,
    terminateBreadcrumb: !!raw.terminateBreadcrumb,
    nextStepRequired: !!raw.nextStepRequired,
    sidebar: !!raw.sb,
    fullscreen: !!raw.fs || (parent ? parent.fullscreen : false),
    sort: !!raw.sort,
    children: emptyObject,
    childrenByPath: emptyObject,
    checkRequirements,
    appBar: raw.appBar,
  }
  
  if (raw.children) {
    cooked.children = Object.fromEntries(
      Object
        .entries(raw.children)
        .map(([key, page]) => [key, cookPage(page, cooked, key)])
        .filter(([key, val]) => !!val)
    );
    
    cooked.childrenByPath = Object.fromEntries(
      Object
        .entries(cooked.children)
        .map(([key, page]: [string, any]) => [page.path.slice(1), page])
    )
  }
  
  return fz(cooked) as CookedPage<T>;
}

const pages: PageRoot<typeof _pages> = Object.fromEntries(Object.entries(_pages).map(([key, page]) => [key, cookPage(page, null, key)]).filter(([key, val]) => !!val));
const pagesByPath: DictionaryRO<CookedPage> = Object.fromEntries(Object.entries(pages).map(([key, page]) => [page.path.slice(1), page]));

// To określa kolejność aplikacji w sidebarze i na stronie frontowej
export const APPS = tuple(pages.teka, pages.cataloguing, pages.wyp, pages.czyt, pages.ubyt, pages.locker, pages.stan, pages.admin);

export const emptyPage: CookedPage = fz({
  id: "",
  path: "",
  fullPath: "",
  icon: "question",
  displayName: "",
  searchName: "",
  sortKey: "",
  description: "",
  keywords: emptyArray,
  access: emptyArray,
  requirements: emptyArray,
  disableForSearching: true,
  displayInBreadcrumb: false,
  terminateBreadcrumb: true,
  nextStepRequired: false,
  sidebar: false,
  sort: false,
  children: emptyObject,
  childrenByPath: emptyObject,
  checkRequirements,
});

/**
 * Zwraca informacje o stronie zapisanej pod podaną ścieżką
 * @param {String} path
 * @return {Object}
 * @example getPage("admin.users.add")
 */
function getPage(path: PageId): Readonly<CookedPage<FlatPages[typeof path]>>;
function getPage(path: string): Readonly<CookedPage>;
function getPage(path: string): Readonly<CookedPage> {
  // @ts-ignore
  return flatPages[path] || emptyPage;
}

function strArray(x: any): readonly string[] {
  if (!x)
    return emptyArray;
  else if (Array.isArray(x))
    return fz(x);
  else
    return fz([x]);
}

/**
 * Pobranie ścieżki URL do wybranej strony
 * @param {String} stringPath ścieżka w obiekcie pages, pod którą zapisana jest strona
 * @param {Object} params Wartości parametrów w ścieżce URL
 * @example getPagePath("teka.doc", { id: 1 }) -> "/teka/doc/1"
 */
function getPagePath(stringPath: string, params: Dictionary = emptyObject) {
  let fullPath = getPage(stringPath).fullPath || "";

  Object.keys(params).forEach(param => {
    fullPath = fullPath.replace(`:${param}`, params[param]);
  });

  return fullPath;
}

export function getPageForLocation(path: string, maxDepth: number = 1000) {
  while (path.startsWith("/")) path = path.slice(1);
  const pathParts = path.split("/");
  let page = pages.home;
  let pool = pagesByPath as DictionaryRO<CookedPage>;
  
  for (let i = 0; i < Math.min(pathParts.length, maxDepth); i++) {
    const part = pathParts[i];
    if (part === "") continue;
    
    const child = pool[part];
    if (!child)
      return page;
    if (!child.childrenByPath)
      return child;
    
    page = child;
    pool = child.childrenByPath;
  }
  
  return page;
}

export { pages, getPage, getPagePath };

function perCatalogue(each: (catalogue: BootstrapCatalogue, pathId: string) => RawPage, requirementPrefix?: string): Dictionary<RawPage> {
  const result: Dictionary<RawPage> = {};
  
  for (const cat of bootstrap.sowa.catalogues) {
    const pathId = `sowa-${cat.cat_id}` // FIXME: nie możemy się pozbyć prefiksu sowa- ?
    const page = each(cat, pathId);
    result[pathId] = {
      displayName: cat.name,
      sortKey: cat.cat_id,
      icon: "rectangle-history",
      ...page,
      path: "/" + pathId,
    }
    if (requirementPrefix)
      result[pathId].rq = [...(result[pathId].rq || emptyArray), requirementPrefix.replace("#", cat.cat_id)];
  }
  
  return result;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// Na poniższą gimnastykę bym sam nie wpadł, zebrane z SO, zmodyfikowane by działało z ["children"]
// https://stackoverflow.com/questions/69095054/how-to-deep-flatten-a-typescript-interface-with-union-types-and-keep-the-full-ob
// https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];

type Join<K, P> = K extends string | number ?
  P extends string | number ?
    `${K}${"" extends P ? "" : "."}${P}`
    : never : never;

type Paths<T, D extends number = 5> =
  [D] extends [never]
    ? never
    : T extends { children: object }
      ? {
        [K in keyof T["children"]]-?:
        K extends string
          ? `${K}` | Join<K, Paths<T["children"][K], Prev[D]>>
          : never
      }[keyof T["children"]]
      : "";

type FollowPath<T, P> =
  T extends { children: object }
    ? P extends `${infer U}.${infer R}`
      ? U extends keyof T["children"]
        ? FollowPath<T["children"][U], R>
        : never
      : P extends keyof T["children"]
        ? T["children"][P]
        : never
    : never;

type Root = {
  children: typeof pages
}

type FlatPages = {
  [K in Paths<Root>]: FollowPath<Root, K>
}

export type PageId = keyof FlatPages; 

const flatPages: FlatPages = flattenPages({ children: pages }) as any;

function flattenPages({ children }: { children: Dictionary }): Dictionary {
  const results = [];
  for (const [key, value] of Object.entries(children)) {
    results.push([key, value]);
    const descendants = flattenPages(value);
    for (const [subkey, subvalue] of Object.entries(descendants)) {
      results.push([`${key}.${subkey}`, subvalue])
    }
  }
  return Object.fromEntries(results);
}

////////////////////////////////////////////////////////////////////////////////////////////////////
